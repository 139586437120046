<!-- This component for _partners_ad/partners Banners -->

<template>
  <div class="partners-blk">
    <div class="wrapper">
      <p class="title">{{ $t('common.our_partners') }}</p>
      <div class="banners-blk">
        <img :src="imgURL(1)" alt="banner" />
        <img :src="imgURL(2)" alt="banner" />
        <img :src="imgURL(3)" alt="banner" />
        <img :src="imgURL(4)" alt="banner" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvertisingBlock',
  methods: {
    imgURL(_name) {
      return require(`~/_partners_ad/banners/${_name}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.partners-blk {
  min-height: 326px;
  border-top: 1px solid rgba(150, 150, 150, 0.5);
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

.banners-blk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  img {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #373a3c;
  margin-bottom: 50px;
  text-align: center;
}

@media (min-width: 768px) {
  .banners-blk {
    flex-direction: row;

    img {
      margin-bottom: 0;
    }
  }

  .title {
    text-align: left;
  }
}
</style>
