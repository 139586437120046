<template>
  <main>
    <div class="main-page">
      <div class="custom-container">
        <h1 class="main-header" @click="toSportsList">
          {{ $t('common.start_trainings_now') }}
        </h1>
        <CenterSearchBlock :url-key="urlKey" />
        <div class="sports-button-wrapper">
          <button class="button transparent-button" @click="toSportsList">
            {{ $t('common.to_sports_types') }}
          </button>
        </div>
      </div>
    </div>
    <div ref="sports" class="letter-filter">
      <div class="letter-list-wrapper">
        <vue-loaders
          v-if="!letters.all.length"
          name="ball-beat"
          color="#fff"
          scale="1"
        />
        <button
          v-else
          class="letter-wrapper"
          :class="activeClass(null)"
          @click="allSportsList"
        >
          {{ letters.firstLast }}
        </button>
        <button
          v-for="(el, idx) in letters.all"
          :key="idx"
          class="letter-wrapper"
          :class="activeClass(el)"
          @click="filter = el"
        >
          {{ el }}
        </button>
      </div>
    </div>
    <div class="sports-block">
      <div v-if="filteredSports.length" class="sports-block-wrapper">
        <IconSportHover v-for="el in filteredSports" :key="el.id" :sport="el" />
      </div>
      <div v-else class="loader-wrapper">
        <vue-loaders name="line-scale-pulse-out" color="#909090" scale="2" />
      </div>
    </div>
    <AdvertisingBlock v-if="isNativeDomain" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import IconSportHover from '@/components/Common/IconSportHover'
import CenterSearchBlock from '@/components/MainPage/CenterSearchBlock'
import MetaHeadMixin from '@/dependencies/mixins/meta-head'
import AdvertisingBlock from '@/components/AdvertisingBlock'
import constants from '@/dependencies/constants'

export default {
  name: 'MainPage',
  components: {
    CenterSearchBlock,
    IconSportHover,
    AdvertisingBlock
  },
  mixins: [MetaHeadMixin],
  transition: 'fade-fast',
  asyncData({ params }) {
    const { location } = params

    return { urlKey: location }
  },
  data: () => ({
    filter: null
  }),
  computed: {
    ...mapGetters({
      letters: 'dict/getAllFirstLetters',
      allSports: 'dict/getAllSports',
      cityDomain: 'seo/getSelectedCity',
      domainLang: 'location/getDomainLang',
      isMobile: 'common/getWidth'
    }),
    domain() {
      return this.cityDomain?.domainTitle
    },
    city() {
      return this.cityDomain?.name
    },
    filteredSports() {
      return this.filter
        ? this.allSports.filter((el) => el.value[0] === this.filter)
        : this.allSports
    },
    metaTitle() {
      return `${this.domain} - ${this.$t('meta.service')} ${
        this.city
      }. ${this.$t('meta.find_trainer')}`
    },
    metaContent() {
      return `${this.$t('meta.all_trainers')} ${this.city},${this.$t(
        'meta.main_descr'
      )}`
    },
    isNativeDomain() {
      return this.domainLang === 'ua'
    }
  },
  methods: {
    activeClass(_el) {
      return this.filter === _el ? 'active' : ''
    },
    toSportsList() {
      const {
        headerHeight: { mobile, desktop }
      } = constants

      const headerHeight = this.isMobile ? mobile : desktop

      const topOfElement =
        window.pageYOffset +
        this.$refs.sports.getBoundingClientRect().top -
        headerHeight
      window.scroll({ top: topOfElement, behavior: 'smooth' })
    },
    allSportsList() {
      this.filter = null
      this.toSportsList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables.scss';

.main-page {
  height: 518px;
  width: 100%;
  background: url('~assets/img/bg_image-mob.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-container {
    padding: 0 10px;
  }

  .center-block {
    margin-bottom: 35px;

    .main-button {
      font-size: 18px;
    }
  }

  .transparent-button {
    font-size: 18px;
  }
}

.sports-block {
  padding: 30px 0;
  max-width: 1200px;
  min-height: 500px;
  margin: 0 auto;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .loader-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.letter-filter {
  background-color: $primary;
}

.main-header {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 120px;
  font-size: 26px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  transition: 0.3s linear;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition: 0.3s linear;
  }
}
.sports-button-wrapper {
  display: flex;
  justify-content: center;
}
.button {
  height: 50px;
}

.letter-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 18px 10px;
  max-width: 1200px;
  margin: 0 auto;

  .vue-loaders {
    margin: 0 auto;
    line-height: 50px;
  }
}

.letter-wrapper {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  min-width: 50px;
  color: #fff;
  font-size: 18px;
  height: 40px;
  width: 25%;

  &.active {
    background-color: #fff;
    border-radius: 3px;
    color: $primary;
  }
}

.letter {
  padding: 0 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid transparent;
  text-align: center;

  &:hover {
    border-right: 1px solid white;
    border-left: 1px solid white;
  }

  &.active {
    border-radius: 3px;
    background-color: white;
    color: $primary;
  }
}

@media (min-width: 768px) {
  .main-page {
    height: 560px;
    width: 100%;
    background-image: url('~assets/img/bg_image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 60%;

    .center-block {
      margin-bottom: 90px;
      padding: 0;
    }

    .transparent-button {
      height: 60px;
      line-height: 55px;
      width: 250px;
    }
  }

  .sports-block {
    &-wrapper {
      justify-content: center;
    }
  }

  .main-header {
    margin-bottom: 30px;
    font-size: 60px;
  }

  .letter-wrapper {
    position: relative;

    &:hover {
      &:before,
      &:after {
        content: '';
        height: 22px;
        position: absolute;
        border-left: 1px solid white;
        top: 9px;
      }
      &:before {
        left: 1px;
      }
      &:after {
        right: 1px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .letter-list-wrapper {
    height: 50px;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 5px 10px;
  }

  .letter-wrapper {
    width: 100%;
  }
}
</style>
