<template>
  <div :class="searchAvail ? 'between' : ''" class="center-block">
    <AutocompleteCustom
      v-model="query"
      :display-attr="'title'"
      :type="2"
      :model="query"
      :placeholder="$t('placeholder.enter_sport_or_club_title')"
      :prefix-class="'icon-search'"
      @select="selectSportTypeClub"
    />
    <DropdownLocation v-if="showDrop" />
    <button class="button main-button" @click="resetPageAndRouteToSearch">
      {{ $t('common.find_a_trainer') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DropdownLocation from '@/components/Dropdowns/DropdownLocation'
import AutocompleteCustom from '@/components/Common/AutocompleteCustom'
import SetSportTypeClub from '@/dependencies/mixins/set-sport-type-club'
import constants from '@/dependencies/constants'

export default {
  name: 'CenterSearchBlock',
  components: {
    DropdownLocation,
    AutocompleteCustom
  },
  mixins: [SetSportTypeClub],
  props: {
    urlKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: '',
      chosenItem: {},
      showDrop: true
    }
  },
  computed: {
    ...mapGetters({
      subway: 'search/getSubwayMap',
      districts: 'search/getCityDistricts',
      city: 'location/getUrlKey'
    }),
    searchAvail() {
      return !!(this.subway || this.districts.length)
    }
  },
  watch: {
    searchAvail: {
      deep: true,
      handler() {
        this.showDrop = this.searchAvail
      }
    }
  },
  created() {
    this.showMiddleBlock()
  },
  methods: {
    ...mapActions({
      setCurrentPage: 'search/SET_CURRENT_PAGE'
    }),
    selectSportTypeClub(_item) {
      this.chosenItem = _item

      this.handleSelection()
    },

    showMiddleBlock() {
      const { citiesWithSubwayDistricts } = constants
      this.showDrop = citiesWithSubwayDistricts.includes(this.urlKey)
    },

    async resetPageAndRouteToSearch() {
      await this.setCurrentPage(1)
      await this.$router.push(
        this.localePath({
          name: 'location-search-trainers-all',
          params: {
            location: this.city,
            0: this.getUrl
          }
        })
      )
    }
  }
}
</script>

<style lang="scss">
@import '~assets/css/variables';

.trainer-wrapper,
.club-wrapper {
  .search-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .center-block {
    justify-content: center;

    &.between {
      justify-content: space-between;
    }
  }

  .trainer-wrapper,
  .club-wrapper {
    background-color: #e6e6e6;

    .search-desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      background: url('../../assets/img/bg_image.jpg') no-repeat center 20%;
      background-size: cover;

      &-container {
        width: 100%;

        .autocomplete-container {
          width: 55% !important;
        }
      }
    }
  }
}
</style>
