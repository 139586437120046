<template>
  <div class="dropdown-choose-location">
    <el-input
      v-model="input"
      :value="input"
      type="text"
      :readonly="true"
      :lock-scroll="true"
      :placeholder="$t('placeholder.choose_district_or_subway_station')"
      :class="'district-subway'"
      @click.native="toggleModal"
      @mouseenter.native="hovering = true"
      @mouseleave.native="hovering = false"
    >
      <i slot="prefix" class="icon-Metro" />
      <i
        v-if="showClear && input"
        slot="suffix"
        class="el-icon-circle-close"
        @click.stop="clear"
      />
    </el-input>
    <el-dialog
      class="location-modal"
      :modal-append-to-body="false"
      :visible.sync="show"
      width="100%"
      :center="true"
    >
      <div class="form-header">
        <span
          :class="{ active: dist, only: !subAvail }"
          @click="tabSw('district')"
        >
          {{ $t('placeholder.district') }}
        </span>
        <span v-if="subAvail" :class="{ active: sub }" @click="tabSw('subway')">
          {{ $t('placeholder.subway') }}
        </span>
      </div>
      <div class="dialog-body">
        <Districts v-if="dist" @clicked="toggleModal" />
        <SubwayStations v-if="sub" @clicked="toggleModal" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Districts from '@/components/Dropdowns/Parts/Districts'
import SubwayStations from '@/components/Dropdowns/Parts/SubwayStations'
import watchScreenWidth from '@/dependencies/mixins/watch-screen-width'

export default {
  name: 'DropdownLocation',
  components: {
    Districts,
    SubwayStations
  },
  mixins: [watchScreenWidth],
  data: () => ({
    show: false,
    tab: 'district',
    input: '',
    hovering: false,
    focused: false
  }),
  computed: {
    ...mapGetters({
      getDistrict: 'search/searchDistrict',
      getMicroDistrict: 'search/searchMicroDistrict',
      getSubway: 'search/searchSubway',
      subwayMap: 'search/getSubwayMap',
      commonSubway: 'search/getCommonSubwayList',
      location: 'search/searchLocation'
    }),
    dist() {
      return this.tab === 'district'
    },
    sub() {
      return this.tab === 'subway'
    },
    subAvail() {
      return this.subwayMap
    },
    showClear() {
      return this.focused || this.hovering
    }
  },
  watch: {
    location() {
      this.tab = !this.subwayMap ? 'district' : this.tab
    },
    getSubway() {
      this.input = this.subwayString()
    },
    getDistrict(newVal) {
      if (this.getSubway.length && !newVal.length) return 1
      else this.input = this.districtString()
    },
    getMicroDistrict(newVal) {
      if (this.getSubway.length && !newVal.length) return 1
      else this.input = this.districtString()
    }
  },
  methods: {
    toggleModal() {
      this.show = !this.show
    },
    tabSw(_tab) {
      this.tab = _tab
    },
    districtString() {
      return [
        ...this.getDistrict.map((item) => item.title),
        ...this.getMicroDistrict.map((item) => item.title)
      ].join(', ')
    },
    subwayString() {
      return this.getSubway
        .map((item) => this.commonSubway[item]?.title)
        .join(', ')
    },
    clear() {
      this.input = ''
      this.$bus.$emit('resetDistricts')
      this.$bus.$emit('resetSubway')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';

.dropdown-choose-location {
  .district-subway {
    margin-bottom: 12px;
  }
}

.form-header {
  height: 50px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  padding: 13px 35% 13px 13px;

  span {
    text-decoration: underline;
    order: 1;

    &:hover {
      cursor: pointer;
    }
  }
  .active {
    text-transform: uppercase;
    text-decoration: none !important;
    font-weight: bold;
    order: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-choose-location {
    .district-subway {
      margin-bottom: 0;
    }
  }

  .form-header {
    span {
      order: unset;
    }
    &.active {
      order: unset;
    }
  }
}
</style>
