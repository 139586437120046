<template>
  <div
    class="sport-btn"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="chooseSportType"
  >
    <div class="image-wrapper">
      <img :src="activeIcon(sport.id)" :alt="`sport${sport.id}`" />
    </div>
    <p>{{ sport.value }}</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import constants from '@/dependencies/constants'

export default {
  name: 'IconSportHover',
  props: {
    sport: {
      type: Object,
      default: () => {}
    },
    clickFunc: {
      type: Function,
      default: () => 1
    }
  },
  data: () => ({
    hover: false
  }),
  computed: {
    ...mapGetters({
      city: 'location/getUrlKey'
    })
  },
  methods: {
    ...mapActions({
      setSportType: 'search/SET_SPORT_TYPE'
    }),
    activeIcon(_id) {
      // @TODO Добавить try-catch, согласовать дефолтную картинку
      try {
        return require(`~/assets/img/sports/${
          this.hover ? 'orange' : 'gray'
        }/${_id}.png`)
      } catch (err) {
        return require(`~/assets/img/sports/${
          this.hover ? 'orange' : 'gray'
        }/null.png`)
      }
    },
    async chooseSportType() {
      const chosenSport = {
        ...this.sport,
        title: this.sport.value,
        type: constants.COMMON.SPORT_PROPERTY
      }
      await this.setSportType(chosenSport)
      this.$router.push(
        this.localePath({
          name: 'location-search-trainers-all',
          params: {
            location: this.city,
            pathMatch: `${chosenSport.type}/${chosenSport.url}`
          }
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

.sport-btn {
  width: 140px;
  margin: 10px 5px 35px 5px;
  animation: show 0.5s ease;

  .image-wrapper {
    position: relative;
    height: 60px;

    img {
      position: absolute;
      left: 0;
    }
  }

  p {
    transition: 0.3s ease;
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    color: #909090;
  }

  &:hover {
    cursor: pointer;

    p {
      transition: 0.3s ease;
      color: $accent;
    }
  }
}

@media (min-width: 768px) {
  .sport-btn {
    margin: 10px 15px 35px 15px;

    &:nth-last-child(-n + 7) {
      margin-bottom: 0;
    }
  }
}

// Appear sports icon animation
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
